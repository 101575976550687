import React from 'react';

import Audits from '../components/views/audits'
import { LayoutLoggedIn } from '../components/views/layout';

export default function() {
    return (
        <LayoutLoggedIn>
            <Audits />
        </LayoutLoggedIn>
    );
}